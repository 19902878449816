import API from 'utils/api';

export const generateNewTicketAPI = (windowId, branchId, phone) =>
  API.get(`/generateNewTicket`, {
    params: {
      windowId,
      branchId,
      phone,
    },
  });

export const submitRatingAPI = reqBody => API.post('/review', reqBody);

export const nextTicketAPI = (windowId, branchId) =>
  API.get(`/setNextTicket/${branchId}/windows/${windowId}`);

export const postponeTicketAPI = reqBody => API.post('/postponeTicket', reqBody);

export const removeTicketAPI = reqBody => API.post('/removeTicket', reqBody);

export const fetchBranchWindowsAPI = branchId => API.get(`/branches/${branchId}`);
