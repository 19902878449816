import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette, mixins }) => ({
  root: {
    '& svg': {
      marginBottom: 4,
    },
  },
  halfCircle: {
    position: 'absolute',
    height: 30,
    width: 30,
    top: 'calc(50% - 15px)',
    borderRadius: '50%',
    backgroundColor: palette.background.default,
    boxShadow: mixins.insetShadows[1],
  },
  leftCircle: {
    left: -20,
  },
  rightCircle: {
    right: -20,
  },
}));
