import React from 'react';
import Button from '@material-ui/core/Button';
import LanguageIcon from '@material-ui/icons/Language';
import { changeLanguage } from 'redux/features/language.slice';
import { useDispatch, useSelector } from 'react-redux';
import useLocale from 'utils/hooks/useLocale';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  '@global': {
    body: {
      '& .MuiTypography-root, & .MuiAlert-root': {
        direction: props => (props.lang === 'en' ? 'ltr' : 'rtl'),
      },
    },
  },
  root: {
    color: ({ color }) => color,
  },
}));

function LanguageButton({ size, color, ...props }) {
  const dispatch = useDispatch();
  const locale = useLocale();
  const lang = useSelector(state => state.language);
  const classes = useStyles({ lang, color });

  return (
    <Button
      size={size}
      className={classes.root}
      startIcon={<LanguageIcon />}
      onClick={() => dispatch(changeLanguage())}
      {...props}
    >
      {locale?.language}
    </Button>
  );
}

LanguageButton.defaultProps = {
  color: '#000',
  size: 'medium',
};

export default LanguageButton;
