import { createAction, createReducer } from '@reduxjs/toolkit';

export const scanQueue = createAction('queues/scanQueue');
export const loadTickets = createAction('queues/loadTickets');
export const receivedTickets = createAction('queues/receivedTickets');
export const syncQueues = createAction('queues/syncQueues');
export const updateTickets = createAction('queues/updateTickets');
export const leaveQueue = createAction('queues/leaveQueue');
export const postponeTurn = createAction('queues/postponeTurn');
export const rateQueue = createAction('queues/rateQueue');
export const setTabIndex = createAction('queues/setTabIndex');

const initialState = {
  tickets: JSON.parse(localStorage.getItem('tickets')) || [],
  branchId: JSON.parse(localStorage.getItem('tickets'))?.[0]?.branchId || '',
  loading: true,
  tab: 0,
};

export default createReducer(initialState, {
  [receivedTickets]: (state, action) => {
    process.env.NODE_ENV !== 'production' &&
      console.log(receivedTickets.toString(), 'slice', action.payload);
    return {
      ...state,
      loading: false,
      tickets: action.payload,
      branchId: action.payload?.[0]?.branchId || '',
    };
  },
  [updateTickets]: (state, action) => {
    process.env.NODE_ENV !== 'production' &&
      console.log(updateTickets.toString(), 'slice', action.payload);
    return {
      ...state,
      tickets: action.payload,
    };
  },
  [setTabIndex]: (state, action) => {
    return {
      ...state,
      tab: action.payload,
    };
  },
});
