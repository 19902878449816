import React from 'react';
import Box from '@material-ui/core/Box';
import banner from 'assets/images/cib.png';
import useStyles from './styles';

function AdBanner() {
  const classes = useStyles();

  return (
    <Box className={classes.bannerContainer}>
      <a
        // href="https://www.facebook.com/CIBEgypt/"
        href="https://www.cibeg.com/ar"
        target="_blank"
        rel="noreferrer"
      >
        <img className={classes.banner} src={banner} alt="Paid ad" />
      </a>
    </Box>
  );
}

export default AdBanner;
