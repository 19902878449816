import { takeLatest, take, call, put, putResolve, select } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import {
  scanQueue,
  receivedTickets,
  syncQueues,
  loadTickets,
  updateTickets,
  leaveQueue,
  postponeTurn,
  rateQueue,
  setTabIndex,
} from 'redux/features/queues.slice';
import { isValidTicket, isValidToken } from 'firestoreUtils';
import { generateNewTicketAPI, postponeTicketAPI, removeTicketAPI, submitRatingAPI } from 'apis';
import branchesRef from 'config/firebase';
import notificationSound from 'assets/sounds/notification.mp3';
import yourTurnSound from 'assets/sounds/your-turn.mp3';

const notificationAudio = new Audio(notificationSound);
const yourTurnAudio = new Audio(yourTurnSound);

const delay = time => new Promise(resolve => setTimeout(resolve, time));
const getQueues = state => state.queues;

function* watchTurn() {
  const { tickets } = yield select(getQueues);

  for (let i = 0; i < tickets.length; i += 1) {
    const { currentTicketNo, ticketNo, peopleBefore, queueId } = tickets[i];

    // if less than 3 people left before user's turn
    if (peopleBefore > 0 && peopleBefore <= 3) {
      notificationAudio.play();
    }

    // During the user's turn
    if (currentTicketNo === ticketNo) {
      yourTurnAudio.play();
    }

    // After the user's turn (when the user's turn is over)
    if (peopleBefore === -1) {
      const newTickets = tickets.map(ticket =>
        ticket.queueId === queueId ? { ...ticket, showRate: true } : ticket,
      );
      yield putResolve(updateTickets(newTickets));
      localStorage.setItem(
        'tickets',
        JSON.stringify(tickets.filter(ticket => ticket.queueId !== queueId)),
      );
    }
  }

  // Update slides (tabs)
  const { tickets: ticketsArr, tab } = yield select(getQueues);

  if (tab >= ticketsArr.length) {
    yield put(setTabIndex(ticketsArr.length - 1));
  }
  const arr = ticketsArr.map(({ peopleBefore }) => peopleBefore);

  if (ticketsArr.some(({ peopleBefore }) => peopleBefore < 3)) {
    const indexToGoto = arr.indexOf(Math.min(...arr));
    yield put(setTabIndex(indexToGoto));
  }
}

function* handleSyncQueues() {
  try {
    yield call(delay, 1000);
    const { branchId, tickets } = yield select(getQueues);
    if (tickets.length === 0) return;
    const channel = yield eventChannel(emit => {
      const unsubscribe = branchesRef.doc(branchId).collection('Windows').onSnapshot(emit);
      return () => unsubscribe();
    });

    while (true) {
      const { docs } = yield take(channel);
      const newTickets = yield tickets.map(ticket => {
        const doc = docs.find(queue => queue.id === ticket.queueId);

        if (ticket.queueId === doc?.id) {
          const { current, name: queueLabel, arName: queueArLabel } = doc.data();
          const peopleInQueue = current.length;
          const currentTicketNo = current?.[0]?.number;
          const peopleBefore = current.findIndex(({ id }) => id === ticket.ticketId);
          const peopleAfter = peopleInQueue - peopleBefore - 1;
          return {
            ...ticket,
            queueLabel,
            queueArLabel,
            currentTicketNo,
            peopleBefore,
            peopleAfter,
          };
        }
        return ticket;
      });
      yield putResolve(updateTickets(newTickets));
      yield localStorage.setItem('tickets', JSON.stringify(newTickets));
      yield call(watchTurn);
    }
  } catch (error) {
    yield console.log(error);
    yield error.response && console.log(error.response);
  }
}

function* handleScanQueue({ payload: { token, branchId, queueId, history, phone } }) {
  try {
    const isValid = yield call(isValidToken, branchId, queueId, token);
    yield process.env.NODE_ENV !== 'production' &&
      console.log({ token, branchId, queueId, isValid });

    // LS here stands for localStorage
    const ticketsInLS = yield JSON.parse(localStorage.getItem('tickets')) || [];

    // if tickets array in LS is empty and token isInvalid
    if ((!ticketsInLS.length && !isValid) || !branchId) {
      yield history.push('/');
    }
    const ticketItemInLS = yield ticketsInLS?.find(ticket => ticket.queueId === queueId);

    //  check if ticket in ticketItemInLS has expired
    const isTicketValid = yield call(isValidTicket, branchId, queueId, ticketItemInLS?.ticketId);

    // if queueId is in localstorage' ticketsArray and its ticket is not expired
    if (ticketItemInLS && isTicketValid) {
      const newTickets = yield ticketsInLS.filter(ticket => ticket.branchId === branchId);
      yield localStorage.setItem('tickets', JSON.stringify(newTickets));
      yield putResolve(receivedTickets(newTickets));
    }

    // if queueId is not in localstorage' ticketsArray or if queueID exists but the ticket in it is expired
    if (!ticketItemInLS || !isTicketValid) {
      // fetch new ticket
      const resp = yield call(generateNewTicketAPI, queueId, branchId, phone);
      yield process.env.NODE_ENV !== 'production' && console.log(resp);
      const {
        currentNumber,
        id,
        remainingInQueue,
        ticketNumber,
        printedAt,
        name,
        arName,
      } = yield resp.data;
      const newTicket = yield {
        peopleBefore: remainingInQueue,
        peopleAfter: 0,
        currentTicketNo: currentNumber,
        ticketNo: ticketNumber,
        ticketId: id,
        queueId,
        branchId,
        printedAt,
        queueLabel: name,
        queueArLabel: arName,
        showRate: false,
      };

      const newTickets = yield [
        // and add new ticket to list of tickets
        newTicket,
        // remove tickets from other branches
        ...ticketsInLS.filter(ticket => ticket.branchId === branchId),
      ];
      yield localStorage.setItem('tickets', JSON.stringify(newTickets));
      yield putResolve(receivedTickets(newTickets));
    }
  } catch (error) {
    yield console.log(error);
    yield error.response && console.log(error.response);
  }
}

function* handleLoadTickets() {
  // LS here stands for localStorage
  const ticketsInLS = yield JSON.parse(localStorage.getItem('tickets')) || [];

  yield putResolve(receivedTickets(ticketsInLS));
}

function* handlePostponeTurn({ payload: { queueId, ticketId, branchId } }) {
  try {
    const { tickets } = yield select(getQueues);

    const resp = yield call(postponeTicketAPI, { windowId: queueId, ticketId, branchId });
    yield process.env.NODE_ENV !== 'production' && console.log(resp);
    const { ticketNumber, id, currentNumber, remainingInQueue, printedAt } = yield resp.data;

    const newTickets = yield tickets.map(ticket =>
      ticket.queueId === queueId
        ? {
            ...ticket,
            ticketId: id,
            ticketNo: ticketNumber,
            currentTicketNo: currentNumber,
            peopleBefore: remainingInQueue - 1,
            peopleAfter: 0,
            printedAt,
          }
        : ticket,
    );

    yield localStorage.setItem('tickets', JSON.stringify(newTickets));
    yield putResolve(receivedTickets(newTickets));
  } catch (error) {
    yield console.log(error);
    yield error.response && console.log(error.response);
  }
}

function* handleRateQueue({ payload: { rating, queueId, ticketId } }) {
  try {
    const { tickets, branchId } = yield select(getQueues);

    const newTickets = yield tickets.filter(ticket => ticket.queueId !== queueId);
    yield localStorage.setItem('tickets', JSON.stringify(newTickets));
    yield putResolve(receivedTickets(newTickets));

    const resp = yield call(submitRatingAPI, {
      review: rating,
      id: ticketId,
      windowId: queueId,
      branchId,
    });
    yield process.env.NODE_ENV !== 'production' && console.log(resp);
  } catch (error) {
    yield console.log(error);
    yield error.response && console.log(error.response);
  }
}

function* handleLeaveQueue({ payload: { queueId, ticketId, branchId } }) {
  try {
    const { tickets } = yield select(getQueues);
    const newTickets = yield tickets.filter(ticket => ticket.queueId !== queueId);

    const resp = yield call(removeTicketAPI, { windowId: queueId, ticketId, branchId });
    yield process.env.NODE_ENV !== 'production' && console.log(resp);

    yield localStorage.setItem('tickets', JSON.stringify(newTickets));
    yield putResolve(receivedTickets(newTickets));
  } catch (error) {
    yield console.log(error);
    yield error.response && console.log(error.response);
  }
}

function* handleStartSync() {
  const { loading, tickets } = yield select(getQueues);

  //  Start firebase synchronization here
  if (!loading && !!tickets.length) {
    yield put(syncQueues());
  }
}

export default function* watchQueue() {
  yield takeLatest(scanQueue.toString(), handleScanQueue);
  yield takeLatest(loadTickets.toString(), handleLoadTickets);
  yield takeLatest(receivedTickets.toString(), handleStartSync);
  yield takeLatest(syncQueues.toString(), handleSyncQueues);
  yield takeLatest(leaveQueue.toString(), handleLeaveQueue);
  yield takeLatest(postponeTurn.toString(), handlePostponeTurn);
  yield takeLatest(rateQueue.toString(), handleRateQueue);
}

/*
http://localhost:3000/scan?queue=NEmEThUtpY8O1JCHb910&branch=51&token=98813162530
http://localhost:3000/scan?queue=kvdQVPUNEbmRdQG0gMAv&branch=51&token=11164905896
*/
