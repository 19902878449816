import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  welcome: {
    '@media (max-width: 370px)': {
      fontSize: '3.3rem',
    },
  },
  scanButton: {
    fontSize: '2.8rem',
    '&&': {
      '& svg': {
        fontSize: 'inherit',
      },
    },
  },
}));
