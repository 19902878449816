import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints }) => ({
  arrow: {
    padding: 0,
    position: 'fixed',
    top: '40%',
    transition: 'all 100ms ease-in-out',
    '& svg': {
      fontSize: '15vw',
      [breakpoints.up('md')]: {
        fontSize: '10vw',
      },
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  forward: {
    right: '2%',
    '&:active': {
      transform: 'translateX(15%) scale(1.1)',
    },
  },
  back: {
    left: '2%',
    '&:active': {
      transform: 'translateX(-15%) scale(1.1)',
    },
  },
}));
