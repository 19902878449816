import branchesRef from 'config/firebase';

// eslint-disable-next-line import/prefer-default-export
export const isValidToken = async (branchId, queueId, token) => {
  if (!branchId || !queueId || !token) return false;
  const branchDoc = branchesRef.doc(branchId);
  let valid = false;
  if ((await branchDoc.get()).exists) {
    const queueDoc = await branchDoc.collection('Windows').doc(queueId).get();

    if (queueDoc.exists) {
      console.log(queueDoc.data().token, token);
      valid = queueDoc.data().token === token;
    }
  }
  return valid;
};

export const isValidTicket = async (branchId, queueId, ticketId) => {
  if (ticketId == null) return false;
  const doc = await branchesRef.doc(branchId).collection('Windows').doc(queueId).get();
  return doc.data().current.some(({ id }) => id === ticketId);
};
