import React, { useState, useEffect } from 'react';
import Layout from 'components/Layout/Layout';
import QueueStats from 'components/QueueStats/QueueStats';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import QueueActions from 'components/QueueActions/QueueActions';
import { loadTickets, rateQueue, setTabIndex } from 'redux/features/queues.slice';
import SwipeTip from 'components/SwipeTip/SwipeTip';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import AdBanner from 'components/AdBanner/AdBanner';
import useStyles from './styles';

function Queues() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tipOpen, setTipOpen] = useState(false);
  const [rating, setRating] = useState(null);
  const { loading, tickets, tab } = useSelector(state => state.queues);

  useEffect(() => {
    if (loading) dispatch(loadTickets());
  }, [dispatch, loading]);

  const onSubmitRating = () => {
    if (rating == null) return;
    dispatch(
      rateQueue({
        rating,
        ticketId: tickets[tab]?.ticketId,
        queueId: tickets[tab]?.queueId,
      }),
    );
    setRating(null);
  };

  const setTab = index => dispatch(setTabIndex(index));

  return !tickets?.length && !loading ? (
    <Redirect to="/" />
  ) : (
    <div className={classes.root}>
      <SwipeTip open={tipOpen} setOpen={setTipOpen} mandatoryCondition={tickets.length > 1} />
      <Zoom in timeout={800}>
        <div>
          <Layout pt={2} pb={3}>
            <AdBanner />
          </Layout>
          <SwipeableViews
            className={classes.slides}
            index={tab}
            onChangeIndex={index => setTab(index)}
          >
            {tickets.map(ticket => (
              <QueueStats key={ticket.ticketId} {...ticket} rating={rating} setRating={setRating} />
            ))}
          </SwipeableViews>
        </div>
      </Zoom>
      <Fade in timeout={400} style={{ transitionDelay: '1100ms' }}>
        <Layout pt={0}>
          <QueueActions
            {...tickets[tab]}
            bullets={[...Array(tickets.length).keys()]}
            tab={tab}
            setTab={setTab}
            onSubmitRating={onSubmitRating}
            isRateDisabled={rating == null}
          />
        </Layout>
      </Fade>
    </div>
  );
}

export default Queues;
