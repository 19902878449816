import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ mixins }) => ({
  root: {
    '& img': {
      position: 'absolute',
      height: '100%',
      width: '100%',
      zIndex: -1,
      filter: mixins.imageShadows[1],
    },
  },
}));
