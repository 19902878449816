import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing }) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: 300,
    },
  },
  actions: {
    justifyContent: 'center',
  },
  errorMsg: {
    paddingTop: spacing(1),
    '& .MuiAlert-icon': {
      alignItems: 'center',
      fontSize: '2.8rem',
      marginInlineEnd: '12px',
      marginRight: 'unset',
    },
    '& .MuiAlert-message': {
      fontSize: '1.4rem',
      marginTop: 2,
      display: 'inline-flex',
      alignItems: 'center',
    },
  },
}));
