import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints, mixins, spacing }) => ({
  backdrop: {
    zIndex: 100,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  swipeIcon: {
    animation: '$swipe 1.8s infinite',
    animationDelay: '500ms',
    position: 'fixed',
    top: 'calc(50vh - 9rem)',
    '& svg': {
      filter: 'drop-shadow( 0px 3px 6px rgba(0, 0, 0, .5))',
      color: '#fff',
      width: 'calc(12rem + 15vw)',
      maxWidth: '22rem',
      height: 'auto',
    },
  },
  label: {
    [breakpoints.up('sm')]: {
      padding: spacing(3, 4, 2.5),
    },
    padding: spacing(2, 3, 1.5),
    margin: spacing(0, 2),
    backgroundColor: 'rgba(0,0,0,0.8)',
    color: '#fff',
    maxWidth: '60rem',
    top: '5%',
    position: 'absolute',
    '& .MuiTypography-root': {
      lineHeight: 1.5,
    },
  },
  button: {
    [breakpoints.up('sm')]: {
      bottom: '8%',
    },
    bottom: '15%',
    position: 'absolute',
    animation: '$rotate 6s infinite 1s linear',
    fontSize: '3rem',
    width: '80%',
    maxWidth: 250,
    color: '#fff',
    backgroundColor: 'rgba(0,0,0,0.87)',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,1)',
    },
  },
  ...mixins.keyframes.swipe,
  ...mixins.keyframes.rotate,
}));
