import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { scanQueue } from 'redux/features/queues.slice';
import useRouteQuery from 'utils/hooks/useRouteQuery';
import animationData from 'assets/lottie/qr-animation.json';
import Lottie from 'react-lottie';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Layout from 'components/Layout/Layout';
import useStyles from './styles';

function Scan() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useRouteQuery('token');
  const branchId = useRouteQuery('branch');
  const queueId = useRouteQuery('queue');
  const phone = useRouteQuery('phone');
  const queues = useSelector(state => state.queues);
  const [countDown, setCountDown] = useState(3.5);

  useEffect(() => {
    dispatch(scanQueue({ token, branchId, queueId, history, phone }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, branchId, queueId]);

  useEffect(() => {
    const timer = countDown > 0 && setInterval(() => setCountDown(st => st - 0.5), 500);
    return () => clearInterval(timer);
  }, [countDown]);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return !queues.loading && countDown === 0 ? (
    <Redirect to="/queues" />
  ) : (
    <Layout>
      <Fade in timeout={600} style={{ transitionDelay: '500ms' }} mountOnEnter>
        <Typography className={classes.typography} variant="h2" align="center">
          Fetching a new ticket ...
        </Typography>
      </Fade>
      <div className={classes.lottie}>
        <Fade in={countDown < 3} timeout={500} mountOnEnter>
          <Lottie options={defaultOptions} height="auto" width="600px" />
        </Fade>
      </div>
    </Layout>
  );
}

export default Scan;
