import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, breakpoints, palette }) => ({
  printNumber: {
    '@media not print': {
      display: 'none',
    },
  },
  action: {
    minWidth: 200,
    minHeight: 80,
    '& .MuiButton-label': {
      fontSize: '3rem',
      '& svg': {
        fontSize: '3rem',
      },
    },
    [breakpoints.down('xs')]: {
      order: 2,
      marginTop: spacing(1),
      width: '100%',
      borderRadius: 0,
    },
  },
  actionsContainer: {
    backgroundColor: palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [breakpoints.down('xs')]: {
      position: 'fixed',
      left: 0,
      bottom: 0,
      width: '100%',
    },
  },
  languageButton: {
    [breakpoints.up('sm')]: {
      margin: spacing(2, 0, 0),
    },
  },
}));
