import React from 'react';
import clsx from 'clsx';
import FlexBox from 'components/FlexBox/FlexBox';
import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';
import useStyles from './styles';

function Bullets({ bullets, tab, setTab }) {
  const classes = useStyles();

  return (
    bullets.length > 1 && (
      <FlexBox fdr className={classes.root}>
        {bullets?.map((val, i, arr) => (
          <FlexBox fdr key={val}>
            <ButtonBase
              type="button"
              onClick={() => setTab(val)}
              className={clsx(classes.bullet, val === tab && classes.selected)}
            />
            {i < arr.length - 1 && <Divider className={classes.divider} />}
          </FlexBox>
        ))}
      </FlexBox>
    )
  );
}

export default Bullets;
