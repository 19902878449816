import React from 'react';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import useStyles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" {...props} ref={ref} />;
});

function FullscreenDialog({ open, action, actionLabel, disabled, children, dialogClassName }) {
  const classes = useStyles();

  return (
    <Dialog
      TransitionComponent={Transition}
      TransitionProps={{
        mountOnEnter: true,
        unmountOnExit: true,
      }}
      open={open}
      aria-label="Hyperone Menu"
      fullScreen
      classes={{ paper: dialogClassName }}
    >
      <DialogContent className={classes.dialogContent}>{children}</DialogContent>
      <Button
        size="large"
        fullWidth
        variant="contained"
        color="primary"
        onClick={action}
        className={classes.closeButton}
        disabled={disabled}
      >
        {actionLabel}
      </Button>
    </Dialog>
  );
}

export default FullscreenDialog;
