import React from 'react';
import ReactDOM from 'react-dom';
import { initiateSentry } from 'config/sentry';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

initiateSentry();
serviceWorker.unregister();
