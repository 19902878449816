import React, { useState } from 'react';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import Layout from 'components/Layout/Layout';
import LanguageButton from 'components/LanguageButton/LanguageButton';
import useLocale from 'utils/hooks/useLocale';
import ScanQr from 'components/ScanQr/ScanQr';
import Button from '@material-ui/core/Button';
import CropFreeIcon from '@material-ui/icons/CropFree';
import FlexBox from 'components/FlexBox/FlexBox';
import useStyles from './styles';

function Welcome() {
  const locale = useLocale();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const fixed = {
    left: '50%',
    style: { transform: 'translateX(-50%)' },
    position: 'fixed',
    width: '100%',
  };

  return (
    <Layout innerProps={{ maxWidth: 600 }}>
      <Fade in style={{ transitionDelay: '600ms' }} timeout={800}>
        <FlexBox top="6%" px={2} maxWidth={600} {...fixed}>
          <Typography className={classes.welcome} align="center" variant="h2" gutterBottom>
            {locale?.welcome}
          </Typography>
          <Typography align="center" variant="h6">
            {locale?.pleaseScan}
          </Typography>
        </FlexBox>
      </Fade>
      <ScanQr open={open} onClose={() => setOpen(false)} />
      <Fade in style={{ transitionDelay: '1200ms' }} timeout={800}>
        <FlexBox top="46%" {...fixed}>
          <Button
            className={classes.scanButton}
            size="large"
            startIcon={<CropFreeIcon />}
            variant="contained"
            onClick={() => setOpen(true)}
          >
            Scan QR Code
          </Button>
        </FlexBox>
      </Fade>
      <Fade in style={{ transitionDelay: '1650ms' }} timeout={800}>
        <FlexBox bottom="8%" {...fixed}>
          <LanguageButton color="#fff" />
        </FlexBox>
      </Fade>
    </Layout>
  );
}

export default Welcome;
