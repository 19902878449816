import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  slides: {
    flex: 1,
    '& > div': {
      '@media (min-height: 750px)': {
        marginTop: '5vh',
      },
    },
  },
}));
