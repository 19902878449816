import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ shape, shadows }) => ({
  bannerContainer: {
    minHeight: 120,
  },
  banner: {
    display: 'block',
    borderRadius: shape.borderRadius,
    boxShadow: shadows[1],
    height: 'auto',
    width: '100%',
    // maxHeight: 120,
    // maxWidth: 400,
    objectFit: 'cover',
  },
}));
