/**
 * From here, you can change every single style injected by Material-UI in an Mui component
 * https://material-ui.com/customization/globals/#css
 */
import store from 'redux/store';
import mixins from './mixins.theme';
import palette from './palette.theme';

export default {
  // Style sheet name ⚛️
  MuiButton: {
    // Name of the rule
    label: {
      // Some CSS
      textTransform: 'none',
      marginBottom: -4,
      '& .MuiButton-startIcon, & .MuiButton-endIcon': {
        marginBottom: 4,
      },
    },
    contained: {
      backgroundColor: palette.background.light,
      color: '#fff',
      boxShadow: mixins.boxShadows.primary.dark,
      '&:hover': {
        backgroundColor: palette.action.hoverLight,
        '@media (hover: none)': {
          backgroundColor: palette.action.hoverLight,
        },
      },
    },
  },
  MuiTypography: {
    root: {
      direction: store.getState()?.language === 'en' ? 'ltr' : 'rtl',
    },
  },
  MuiSkeleton: {
    root: {
      animation: '$pulse 1.5s ease-in-out 0.3s infinite',
    },
    ...mixins.keyframes.pulse,
  },
};
