import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  dialog: {
    '&&&': {
      zIndex: 2000,
    },
    '& .MuiTypography-root, & .MuiButton-label': {
      fontWeight: 'bold',
    },
  },
}));
