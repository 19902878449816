import React, { memo, forwardRef } from 'react';
import Box from '@material-ui/core/Box';

const FlexBox = forwardRef(({ children, fdr, jcs, ...props }, ref) => {
  return (
    <Box
      ref={ref}
      display="flex"
      alignItems="center"
      justifyContent={jcs ? 'flex-start' : 'center'}
      flexDirection={fdr ? 'row' : 'column'}
      {...props}
    >
      {children}
    </Box>
  );
});

FlexBox.displayName = 'FlexBox';

export default memo(FlexBox);
