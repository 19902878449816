import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, breakpoints, palette }) => ({
  actions: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '& .MuiButtonGroup-grouped': {
      flex: 1,
    },
    '& .MuiButton-contained.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.72)',
      backgroundColor: palette.primary.light,
    },
    [breakpoints.down('xs')]: {
      order: 2,
      marginTop: spacing(0.5),
      width: '100%',
      '& button': {
        borderRadius: 0,
        width: '100%',
      },
    },
  },
  firstGroup: {
    '& button': {
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  secondGroup: {
    '& button': {
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
    },
  },
  rateButton: {
    fontSize: '3rem',
    '& .MuiButton-startIcon, & .MuiButton-endIcon': {
      marginBottom: 6,
      '& svg': {
        fontSize: '3.5rem',
      },
    },
  },
  actionsContainer: {
    backgroundColor: palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [breakpoints.down('xs')]: {
      position: 'fixed',
      left: 0,
      bottom: 0,
      width: '100%',
    },
  },
  languageButton: {
    margin: spacing(0.5, 0, 0),
    [breakpoints.up('sm')]: {
      margin: spacing(2, 0, 0),
    },
  },
  menuImg: {
    display: 'block',
    height: '100%',
    width: '100%',
  },
}));
