import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing }) => ({
  typography: {
    margin: spacing('8vh', 3, 0),
    direction: 'ltr',
  },
  lottie: {
    position: 'fixed',
    top: '50%',
    transform: 'translate(46px, -300px)',
  },
}));
