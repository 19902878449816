import React, { useEffect, useState, useRef } from 'react';
import Layout from 'components/Layout/Layout';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Collapse from '@material-ui/core/Collapse';
import PeopleIcon from '@material-ui/icons/People';
import ForwardIcon from '@material-ui/icons/Forward';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import Card from 'components/Card/Card';
import TicketCard from 'components/TicketCard/TicketCard';
import useLocale from 'utils/hooks/useLocale';
import branchesRef from 'config/firebase';
import { useHistory } from 'react-router-dom';
import LanguageButton from 'components/LanguageButton/LanguageButton';
import Number from 'components/Number/Number';
import FlexBox from 'components/FlexBox/FlexBox';
import { generateNewTicketAPI, nextTicketAPI } from 'apis';
import { useReactToPrint } from 'react-to-print';
import useRouteQuery from 'utils/hooks/useRouteQuery';
import { isValidToken } from 'firestoreUtils';
import { useSelector } from 'react-redux';
import useStyles from './styles';

function Manage() {
  const classes = useStyles();
  const locale = useLocale();
  const history = useHistory();
  const isPrinter = history.location.pathname.includes('printer');
  const lang = useSelector(state => state.language);

  const token = useRouteQuery('token');
  const branchId = useRouteQuery('branch');
  const queueId = useRouteQuery('queue');

  const [label, setLabel] = useState('');
  const [arLabel, setArLabel] = useState('');
  const [currentTicket, setCurrentTicket] = useState(null);
  const [currentPhone, setCurrentPhone] = useState(null);
  const [peopleInQueue, setPeopleInQueue] = useState(null);
  const [newTicketNumber, setNewTicketNumber] = useState(0);
  const [countDown, setCountDown] = useState(0);

  // Disable the print/next button for a specific interval
  useEffect(() => {
    const timer = countDown > 0 && setInterval(() => setCountDown(st => st - 1), 1000);
    return () => clearInterval(timer);
  }, [countDown]);

  useEffect(() => {
    isValidToken(branchId, queueId, token).then(isValid => {
      if (!isValid) {
        history.push('/');
        return;
      }
      branchesRef
        .doc(branchId)
        .collection('Windows')
        .doc(queueId)
        .onSnapshot(snapShot => {
          const { current, name, arName } = snapShot.data() || {};
          setArLabel(arName);
          setLabel(name);
          setCurrentTicket(current?.[0]?.number);
          setCurrentPhone(current?.[0]?.phone);
          setPeopleInQueue(current?.length);
        });
      // return () => unsubscribe();
    });
  }, [queueId, history, token, branchId]);

  const handleNext = async () => {
    try {
      setCountDown(2);
      nextTicketAPI(queueId, branchId);
    } catch (error) {
      console.log(error);
      error.response && console.log(error.response);
    }
  };

  /* --- Printing --- */
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrintTicket = async () => {
    setCountDown(5);
    const resp = await generateNewTicketAPI(queueId, branchId);
    setNewTicketNumber(resp.data.ticketNumber);
    handlePrint();
  };

  return (
    <Layout>
      <Grow in timeout={600}>
        <FlexBox>
          <Typography align="center" variant="h5" gutterBottom>
            {locale?.queue}
          </Typography>

          <Collapse in={!!currentTicket} timeout={600}>
            <Typography align="center" variant="h3">
              {lang === 'en' ? label : `${arLabel}`}
            </Typography>
            <TicketCard
              m={3}
              mb={2}
              label={locale?.current}
              loading={currentTicket == null}
              number={currentTicket}
            />
          </Collapse>

          <Collapse in={!!currentPhone} timeout={600}>
            <Card mt={2.5} mb={5} px={1}>
              <Box display="flex" alignItems="center">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  p={2.5}
                  pb={1}
                  minWidth={128}
                >
                  <Typography variant="body1" gutterBottom>
                    {locale?.phoneNumber}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {currentPhone}
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Collapse>

          <Card mt={2.5} mb={5} px={1}>
            <Box display="flex" alignItems="center">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                p={2.5}
                pb={1}
                minWidth={128}
              >
                <PeopleIcon />
                <Typography variant="body1" gutterBottom>
                  {locale?.peopleInQueue}
                </Typography>
                <Number
                  loading={peopleInQueue === null}
                  typographyProps={{
                    variant: 'h2',
                  }}
                  skeletonProps={{
                    width: 58,
                    height: 70,
                  }}
                >
                  {peopleInQueue}
                </Number>
              </Box>
            </Box>
          </Card>
        </FlexBox>
      </Grow>
      <Box minHeight={90}>
        <Box className={classes.actionsContainer}>
          <Typography
            className={classes.printNumber}
            variant="h1"
            align="center"
            ref={componentRef}
          >
            #{newTicketNumber}
          </Typography>
          {isPrinter ? (
            <Button
              className={classes.action}
              variant="contained"
              color="primary"
              size="large"
              startIcon={<ConfirmationNumberIcon />}
              onClick={handlePrintTicket}
              disabled={countDown > 0}
            >
              {countDown || locale?.printTicket}
            </Button>
          ) : (
            <Button
              className={classes.action}
              variant="contained"
              color="primary"
              size="large"
              startIcon={<ForwardIcon />}
              onClick={handleNext}
              disabled={!peopleInQueue || countDown > 0}
            >
              {countDown || locale?.next}
            </Button>
          )}

          <Box className={classes.languageButton}>
            <LanguageButton />
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}

export default Manage;
