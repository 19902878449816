import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ mixins }) => ({
  skeleton: {
    transform: 'scale(1, 0.75)',
  },
  number: {
    fontWeight: 400,
  },
  pulsate: {
    animation: '$pulsate 700ms infinite',
  },
  ...mixins.keyframes.pulsate,
}));
