import { makeStyles } from '@material-ui/core/styles';

export const BgHeight = 280;

export default makeStyles(({ palette, shadows }) => ({
  '@keyframes animateBg': {
    '0%': { backgroundPosition: '0% 50%' },
    '50%': { backgroundPosition: '100% 50%' },
    '100%': { backgroundPosition: '0% 50%' },
  },
  root: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    zIndex: -2,
    backgroundImage: `linear-gradient(to right, ${palette.secondary.dark}, ${palette.primary.main} 90%)`,
    backgroundSize: '130% 130%',
    animation: '$animateBg 6s ease infinite',
  },
  bg: {
    boxShadow: shadows[2],
    height: ({ expanded }) => (expanded == null ? 0 : expanded ? '100%' : 'calc(166px + 4vw )'),
    borderBottomLeftRadius: ({ expanded }) =>
      expanded == null ? '80% 180px' : expanded ? 0 : '50% 56px',
    borderBottomRightRadius: ({ expanded }) =>
      expanded == null ? '80% 180px' : expanded ? 0 : '50% 56px',
    backgroundColor: palette.background.default,
    position: 'fixed',
    zIndex: -1,
    width: '100%',
    top: 0,
    overflow: 'hidden',
    transition: 'height 600ms ease 200ms, border-radius 500ms ease 200ms',
  },
}));
