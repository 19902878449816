import React from 'react';
import TicketCard from 'components/TicketCard/TicketCard';
import useLocale from 'utils/hooks/useLocale';
import Grow from '@material-ui/core/Grow';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Number from 'components/Number/Number';
import FlexBox from 'components/FlexBox/FlexBox';
import Card from 'components/Card/Card';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import { useSelector } from 'react-redux';
import Rating from '@material-ui/lab/Rating';
import useStyles from './styles';

function QueueStats({
  showRate,
  ticketNo,
  peopleBefore,
  currentTicketNo,
  queueLabel,
  queueArLabel,
  rating,
  setRating,
}) {
  const classes = useStyles();
  const locale = useLocale();
  const lang = useSelector(state => state.language);
  const up350 = useMediaQuery('(min-width:350px)');
  const up370 = useMediaQuery('(min-width:370px)');

  return (
    <FlexBox>
      <Typography variant="h6" align="center" gutterBottom>
        {showRate ? locale?.rate : locale?.yourPosition}
      </Typography>

      <Typography align="center" variant="h3">
        {lang === 'en' ? queueLabel : `${queueArLabel}`}
      </Typography>

      <TicketCard
        mx={3}
        my={2.5}
        label={locale?.ticket}
        loading={ticketNo == null}
        number={ticketNo}
      />

      <Grow in={showRate} timeout={700} mountOnEnter unmountOnExit>
        <Rating
          className={classes.rating}
          name="rate-queue"
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
        />
      </Grow>

      {!showRate && (
        <>
          <Grow
            in={peopleBefore <= 3 && peopleBefore != null}
            timeout={1000}
            mountOnEnter
            unmountOnExit
          >
            <Typography
              variant={peopleBefore === 0 ? 'h4' : 'h5'}
              className={classes.rotate}
              align="center"
            >
              {peopleBefore === 0 ? locale?.yourTurn : locale?.almostYourTurn}
            </Typography>
          </Grow>

          <Card mt={2.5} mb={5} px={1}>
            <Box display="flex" alignItems="center">
              <FlexBox p={2} pb={1} minWidth={up370 ? 128 : up350 ? 110 : 96}>
                <PeopleIcon />
                <Typography variant="body2" gutterBottom>
                  {locale?.people}
                </Typography>
                <Number
                  loading={peopleBefore == null}
                  animate={peopleBefore <= 3}
                  typographyProps={{
                    variant: 'h3',
                  }}
                  skeletonProps={{
                    width: 70,
                    height: 54,
                  }}
                >
                  {peopleBefore}
                </Number>
              </FlexBox>
              <FlexBox
                borderLeft={1}
                style={{ borderLeftStyle: 'dashed' }}
                borderColor="divider"
                p={2}
                pb={1}
                minWidth={up370 ? 128 : up350 ? 110 : 96}
              >
                <PersonIcon />
                <Typography variant="body2" gutterBottom>
                  {locale?.current}
                </Typography>

                <Number
                  loading={currentTicketNo == null}
                  animate={ticketNo === currentTicketNo}
                  typographyProps={{
                    variant: 'h3',
                  }}
                  skeletonProps={{
                    width: 70,
                    height: 54,
                  }}
                >
                  #{currentTicketNo}
                </Number>
              </FlexBox>
            </Box>
          </Card>
        </>
      )}
    </FlexBox>
  );
}

export default QueueStats;
