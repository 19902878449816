export default {
  language: 'English',
  thankYou: 'شكراً على اتنظارك',
  yourPosition: 'هذا هو موقعك في قائمة إنتظار',
  ticket: 'رقمك',
  people: 'عدد الافراد قبلك',
  current: 'الرقم الحالي',
  leave: 'مغادرة القائمة',
  postpone: 'تأجيل الدور',
  yourTurn: 'حان دورك الآن!',
  almostYourTurn: 'باقي عدد قليل من الأفراد قبلك، من فضلك استعد',
  welcome: 'مرحباً بك',
  pleaseScan:
    'اضغط على الزر أدناه ثم قم بتوجيه كاميرا هاتفك الذكي نحو رمز الإستجابة السريع للحصول على تذكرة افتراضية',
  peopleInQueue: 'عدد الأفراد في قائمة الإنتظار',
  next: 'التالي',
  queue: 'قائمة إنتظار',
  getTicket: 'اضغط هنا للحصول على رقم في قائمة الإنتظار',
  cancel: 'الغاء',
  ppWarnTitle: 'تأجيل دورك؟',
  ppWarnDescription: 'تحذير! سوف يتم نقل دورك الى اخر قائمة الإنتظار',
  ppWarnProceedLabel: 'تأجيل الدور',
  leaveWarnTitle: 'مغادرة قائمة الإنتظار؟',
  leaveWarnDescription: 'هل انت متأكد انك تريد الغاء دورك ومغادرة القائمة',
  leaveWarnProceedLabel: 'مغادرة القائمة',
  printTicket: 'طباعة رقم',
  viewMenu: 'قائمة الخدمات',
  close: 'اغلاق',
  submit: 'إرسال',
  submitRating: 'تقييم',
  rate: 'رأيك يهمنا في قائمة إنتظار',
  swipeTip: 'أنت الأن في أكثر من قائمة إنتظار، قم بتحريك الشاشة بإصبعك لليمين واليسار',
  gotit: 'حسناً',
  scanQueue: 'قائمة إنتظار جديدة',
  retry: 'محاولة مرة اخرى',
  invalidQrErrMsg: 'رمز الإستجابة السريع غير صحيح',
  // 'The browser you are using is not supported, please use your camera to scan the QR code'
  browserNotSupportedErrMsg:
    'المتصفح المُستخدم لا يدعم خاصية التقاط رمز الإستجابة السريع، من فضلك استخدم كاميرا هاتفك الذكي',
  selectWindow: 'من فضلك قم بإختيار الخدمة',
  phoneNumberDialogTitle: 'من فضلك قم بإدخال رقم الموبيل',
  phoneNumber: 'رقم الموبيل',
  skip: 'تخطي',
  phoneValidationError: 'من فضلك قم بإدخال رقم صحيح',
};
