import React from 'react';
import FlexBox from 'components/FlexBox/FlexBox';

function Layout({ children, innerProps, ...props }) {
  return (
    <FlexBox pt={4} px={2} pb={1.5} {...props}>
      <FlexBox {...innerProps}>{children}</FlexBox>
    </FlexBox>
  );
}

export default Layout;
