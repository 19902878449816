import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import useRouteQuery from 'utils/hooks/useRouteQuery';
import Layout from 'components/Layout/Layout';
import { fetchBranchWindowsAPI } from 'apis';
import Box from '@material-ui/core/Box/Box';
import Button from '@material-ui/core/Button/Button';
import Typography from '@material-ui/core/Typography/Typography';
import useLocale from 'utils/hooks/useLocale';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FlexBox from 'components/FlexBox/FlexBox';
import LanguageButton from 'components/LanguageButton/LanguageButton';

function Windows() {
  const locale = useLocale();
  const lang = useSelector(state => state.language);
  const history = useHistory();
  const branchId = useRouteQuery('branch');
  const [valid, setValid] = useState(true);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const [phone, setPhone] = useState('');
  const [phoneIsValid, setPhoneIsValid] = useState(true);
  const [windows, setWindows] = useState([]);

  const fixed = {
    left: '50%',
    margin: '5% 0',
    style: { transform: 'translateX(-50%)' },
    position: 'relative',
    width: '100%',
  };

  const isDisabled = phone.length === 0 || !phoneIsValid;

  const handlePhoneChange = e => {
    const val = e.target.value;
    setPhone(val);
    setPhoneIsValid((val.length === 11 && val.substring(0, 2) === '01') || val.length === 0);
  };

  const handleClose = () => setOpen(false);

  const handleBranchWindows = async () => {
    await fetchBranchWindowsAPI(branchId)
      .then(({ data: { windows: branchWindows } }) => {
        setWindows(branchWindows);
        setLoading(false);
      })
      .catch(err => {
        setValid(false);
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  useEffect(() => {
    handleBranchWindows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchId]);

  const handleSelection = (token, queueId) => {
    const scanQuery = `/scan?branch=${branchId}&token=${token}&queue=${queueId}&phone=${phone}`;
    history.push(scanQuery);
  };

  return !valid ? (
    <Redirect to="/queues" />
  ) : (
    <Layout innerProps={{ flex: 1, width: '100%', maxWidth: 600 }}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Dialog
          fullWidth
          maxWidth="xs"
          keepMounted
          open={open}
          aria-labelledby="phone-number"
          aria-describedby="phone-number-description"
        >
          <DialogTitle id="phone-number">{locale?.phoneNumberDialogTitle}</DialogTitle>
          <DialogContent>
            <TextField
              error={!phoneIsValid}
              helperText={!phoneIsValid ? locale.phoneValidationError : ''}
              autoFocus
              value={phone}
              onChange={handlePhoneChange}
              id="phone"
              label={locale.phoneNumber}
              type="number"
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">+2</InputAdornment>,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button size="large" onClick={handleClose}>
              {locale.skip}
            </Button>
            <Button
              size="large"
              variant="contained"
              color="primary"
              disabled={isDisabled}
              onClick={handleClose}
            >
              {locale.submit}
            </Button>
          </DialogActions>
        </Dialog>
        <Fade in timeout={600} style={{ transitionDelay: '500ms', flex: 0.3 }} mountOnEnter>
          <Typography variant="h4" component="div" style={{ marginBottom: '2rem' }}>
            {locale?.selectWindow}
          </Typography>
        </Fade>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          {loading ? (
            <Box
              style={{
                padding: '1.5rem 1rem',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {windows.map(({ name, arName, token, id }, i) => (
                <Box
                  key={i}
                  style={{
                    padding: '1.5rem 1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    style={{
                      padding: '2rem 1rem',
                      backgroundColor: '#fff8',
                      boxShadow: '.1rem .2rem #8887',
                    }}
                    onClick={() => handleSelection(token, id)}
                  >
                    {lang === 'en' ? name : arName}
                  </Button>
                </Box>
              ))}
            </>
          )}
        </Box>
        <Fade in style={{ transitionDelay: '1650ms' }} timeout={800}>
          <FlexBox bottom="8%" {...fixed}>
            <LanguageButton color="#fff" />
          </FlexBox>
        </Fade>
      </Box>
    </Layout>
  );
}

export default Windows;
