import React, { useState } from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CloseIcon from '@material-ui/icons/Close';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LanguageButton from 'components/LanguageButton/LanguageButton';
import { postponeTurn, leaveQueue } from 'redux/features/queues.slice';
import { useDispatch } from 'react-redux';
import useLocale from 'utils/hooks/useLocale';
import WarningDialog from 'components/WarningDialog/WarningDialog';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import CropFreeIcon from '@material-ui/icons/CropFree';
import hyperoneMenu from 'assets/images/hyperone-menu.jpg';
import FullscreenDialog from 'components/FullscreenDialog/FullscreenDialog';
import Bullets from 'components/Bullets/Bullets';
import Arrows from 'components/Arrows/Arrows';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import ScanQr from 'components/ScanQr/ScanQr';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';

function QueueActions({
  ticketId,
  queueId,
  branchId,
  peopleAfter,
  bullets,
  tab,
  setTab,
  showRate,
  onSubmitRating,
  isRateDisabled,
}) {
  const up600 = useMediaQuery('(min-width:600px)');
  const classes = useStyles();
  const dispatch = useDispatch();
  const locale = useLocale();
  const history = useHistory();

  const [dialogOpen, setDialogOpen] = useState('');
  const [menuDialogOpen, setMenuDialogOpen] = useState(false);
  const [qrDialogOpen, setQRDialogOpen] = useState(false);

  const onCloseMenu = () => setMenuDialogOpen(false);

  return (
    <Box minHeight={up600 ? 'unset' : bullets.length > 1 ? 140 : 90}>
      <Box className={classes.actionsContainer}>
        <ScanQr open={qrDialogOpen} onClose={() => setQRDialogOpen(false)} />

        <FullscreenDialog
          open={menuDialogOpen}
          action={onCloseMenu}
          onClose={onCloseMenu}
          actionLabel={locale.close}
        >
          <img src={hyperoneMenu} alt="hyper-one-menu" className={classes.menuImg} />
        </FullscreenDialog>

        <WarningDialog
          open={dialogOpen === 'postpone'}
          onClose={() => setDialogOpen('')}
          onProceed={() => {
            dispatch(postponeTurn({ queueId, ticketId, branchId }));
            setDialogOpen('');
          }}
          title={locale?.ppWarnTitle}
          description={locale?.ppWarnDescription}
          closeLabel={locale?.cancel}
          proceedLabel={locale?.ppWarnProceedLabel}
        />
        <WarningDialog
          open={dialogOpen === 'leave'}
          onClose={() => setDialogOpen('')}
          onProceed={() => {
            dispatch(leaveQueue({ queueId, ticketId, branchId }));
            setDialogOpen('');
          }}
          title={locale?.leaveWarnTitle}
          description={locale?.leaveWarnDescription}
          closeLabel={locale?.cancel}
          proceedLabel={locale?.leaveWarnProceedLabel}
        />

        <Arrows bullets={bullets} tab={tab} setTab={setTab} />
        <Bullets bullets={bullets} tab={tab} setTab={setTab} />

        {showRate && (
          <ButtonGroup
            orientation="vertical"
            className={classes.actions}
            variant="contained"
            color="primary"
          >
            <Button
              disabled={isRateDisabled}
              className={classes.rateButton}
              size="large"
              startIcon={<StarBorderRoundedIcon />}
              onClick={onSubmitRating}
            >
              {locale?.submitRating}
            </Button>
          </ButtonGroup>
        )}

        {!showRate && (
          <ButtonGroup
            orientation="vertical"
            className={classes.actions}
            variant="contained"
            color="primary"
          >
            <ButtonGroup className={classes.firstGroup}>
              <Button
                fullWidth
                size="large"
                startIcon={<MenuBookIcon />}
                onClick={() => history.push(`/windows?branch=${branchId}`)}
              >
                {locale?.viewMenu}
              </Button>
              <Button
                fullWidth
                size="large"
                startIcon={<CropFreeIcon />}
                onClick={() => setQRDialogOpen(true)}
              >
                {locale?.scanQueue}
              </Button>
            </ButtonGroup>

            <ButtonGroup className={classes.secondGroup}>
              <Button size="large" startIcon={<CloseIcon />} onClick={() => setDialogOpen('leave')}>
                {locale?.leave}
              </Button>
              <Button
                size="large"
                startIcon={<AccessTimeIcon />}
                disabled={peopleAfter === 0}
                onClick={() => setDialogOpen('postpone')}
              >
                {locale?.postpone}
              </Button>
            </ButtonGroup>
          </ButtonGroup>
        )}
        <Box className={classes.languageButton}>
          <LanguageButton />
        </Box>
      </Box>
    </Box>
  );
}

export default QueueActions;
