import React, { memo } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';

function Number({ loading, animate, typographyProps, skeletonProps, children }) {
  const classes = useStyles();

  return loading ? (
    <Skeleton animation="wave" className={classes.skeleton} {...skeletonProps} />
  ) : (
    <Typography
      className={animate ? `${classes.pulsate} ${classes.number}` : `${classes.number}`}
      {...typographyProps}
    >
      {children}
    </Typography>
  );
}

export default memo(Number);
