import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import clsx from 'clsx';
import useStyles from './styles';

function Arrows({ tab, setTab, bullets }) {
  const classes = useStyles();
  const backDisabled = !(tab > 0);
  const forwardDisabled = !(tab < bullets.length - 1);

  return (
    bullets.length > 1 && (
      <>
        <IconButton
          disableRipple
          disabled={backDisabled}
          onClick={() => !backDisabled && setTab(tab - 1)}
          className={clsx(classes.arrow, classes.back)}
        >
          <ArrowBackIosRoundedIcon />
        </IconButton>
        <IconButton
          disableRipple
          disabled={forwardDisabled}
          onClick={() => !forwardDisabled && setTab(tab + 1)}
          className={clsx(classes.arrow, classes.forward)}
        >
          <ArrowForwardIosRoundedIcon />
        </IconButton>
      </>
    )
  );
}

export default Arrows;
