/**
 * Learn more about palette customization
 * https://material-ui.com/customization/palette/
 *
 * Default theme palette values provided by MUI
 * https://material-ui.com/customization/default-theme/?expand-path=$.palette
 */

export default {
  background: {
    default: 'rgb(232, 232, 239)',
    light: 'rgba(255, 255, 255, 0.15)',
  },
  gradient: {
    primary: 'linear-gradient(to left, rgb(178, 71, 107), #663399)',
  },
  primary: { main: '#663399' },
  secondary: { main: '#FF6699' },
  text: {
    primary: 'rgba(0,0,0,0.75)',
    secondary: 'rgba(0,0,0,0.45)',
    tertiary: 'rgba(0,0,0,0.35)',
    quat: 'rgba(0,0,0,0.55)',
  },
  action: {
    disabledLight: 'rgba(255, 255, 255, 0.26)',
    hoverLight: 'rgba(255, 255, 255, 0.30)',
    disabledSecondary: 'rgba(255, 102, 153,0.5)',
  },
};
