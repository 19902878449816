import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, mixins }) => ({
  rotate: {
    animation: '$rotate 4s infinite 1s linear',
  },
  rating: {
    margin: spacing(2, 0),
    fontSize: '5rem',
    justifyContent: 'center',
  },
  ...mixins.keyframes.rotate,
}));
