import React, { useEffect } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { SwipeIcon } from 'assets/svg-components';
import useLocale from 'utils/hooks/useLocale';
import differenceInDays from 'date-fns/differenceInDays';
import useStyles from './styles';

export default function SwipeTip({ open, setOpen, mandatoryCondition }) {
  const classes = useStyles();
  const locale = useLocale();

  useEffect(() => {
    const { visits, date } = JSON.parse(localStorage.getItem('recurringUser')) ?? {};

    if (
      mandatoryCondition &&
      (visits == null || visits < 2 || differenceInDays(new Date(), new Date(date)) > 30)
    ) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeTip = e => {
    e.stopPropagation();
    setOpen(false);

    const { visits } = JSON.parse(localStorage.getItem('recurringUser')) || {};
    localStorage.setItem(
      'recurringUser',
      JSON.stringify({
        visits: visits ? visits + 1 : 1,
        date: new Date(),
      }),
    );
  };

  return (
    <Backdrop
      mountOnEnter
      unmountOnExit
      transitionDuration={{ appear: 800, enter: 800, exit: 500 }}
      style={{ transitionDelay: '400ms' }}
      className={classes.backdrop}
      open={open}
      onClick={closeTip}
    >
      <Paper elevation={2} className={classes.label}>
        <Typography variant="h4" align="center">
          {locale.swipeTip}
        </Typography>
      </Paper>
      <div className={classes.swipeIcon}>
        <SwipeIcon />
      </div>
      <Button size="large" variant="contained" className={classes.button} onClick={closeTip}>
        {locale.gotit}
      </Button>
    </Backdrop>
  );
}
