/**
 * Default theme mixins provided by MUI
 * https://material-ui.com/customization/default-theme/?expand-path=$.mixins
 */

import palette from './palette.theme';

export default {
  scrollbar: {
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
    },
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: palette.secondary.main,
      borderRadius: 8,
    },
  },
  keyframes: {
    swipe: {
      '@keyframes swipe': {
        '0%': {
          opacity: 0,
          transform: 'translateX(calc(10vw + 36px))',
        },
        '25%': {
          opacity: 1,
        },
        '52%': {
          opacity: 1,
        },
        '80%': {
          opacity: 0,
          transform: 'translateX(calc(-10vw - 36px))',
        },
        '100%': {
          opacity: 0,
        },
      },
    },
    pulse: {
      '@keyframes pulse': {
        '0%': {
          opacity: 1,
        },
        '50%': {
          opacity: 0.4,
        },
        '100%': {
          opacity: 1,
        },
      },
    },
    pulsate: {
      '@keyframes pulsate': {
        '0%': {
          transform: 'scale(1)',
        },
        '50%': {
          transform: 'scale(1.15)',
        },
        '100%': {
          transform: 'scale(1)',
        },
      },
    },
    rotate: {
      '@keyframes rotate': {
        '0%': {
          transform: 'rotate(0)',
        },
        '46%': {
          transform: 'rotate(0)',
        },
        '49%': {
          transform: 'rotate(-10deg)',
        },
        '51%': {
          transform: 'rotate(10deg)',
        },
        '54%': {
          transform: 'rotate(0)',
        },
        '100%': {
          transform: 'rotate(0)',
        },
      },
    },
  },
  insetShadows: {
    1: 'inset 0px 2px 1px -1px rgba(0,0,0,0.2), inset 0px 1px 1px 0px rgba(0,0,0,0.14), inset 0px 1px 3px 0px rgba(0,0,0,0.12)',
    2: 'inset 0px 2px 1px -1px rgba(0,0,0,0.2), inset 0px 1px 1px -3px rgba(0,0,0,0.14), inset 0px 1px 3px -3px rgba(0,0,0,0.12)',
    3: 'inset 0px 2px 1px -1px rgba(0,0,0,0.2),',
  },
  imageShadows: {
    1: 'drop-shadow(0px 2px 1px rgba(0,0,0,0.2))',
  },
  boxShadows: {
    primary: {
      light: '0px 1px 4px 0px rgba(150, 150, 150, 0.30)',
      dark: '0px 1px 4px 0px rgba(0, 0, 0, 0.10)',
    },
  },
};
