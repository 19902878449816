import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Collapse from '@material-ui/core/Collapse';
import DialogActions from '@material-ui/core/DialogActions';
import QrReader from 'react-qr-reader';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import useLocale from 'utils/hooks/useLocale';
import useStyles from './styles';

function ScanQr({ open, onClose }) {
  const classes = useStyles();
  const [error, setError] = useState('');
  const history = useHistory();
  const locale = useLocale();

  const handleScan = data => {
    try {
      // testing transitions without qr code
      // history.push(`/scan?queue=NEmEThUtpY8O1JCHb910&branch=51&token=98813162530`);
      if (data) {
        const url = new URL(data);
        const params = new URLSearchParams(url.search);
        const queueId = params.get('queue');
        const branchId = params.get('branch');
        const token = params.get('token');
        if (/[a-zA-Z0-9]{20}/.test(queueId) && token && branchId) {
          onClose();
          history.push(`/scan${url.search}`);
        } else if (branchId) {
          history.push(`/windows${url.search}`);
        } else {
          setError(locale?.invalidQrErrMsg);
        }
      }
    } catch (err) {
      console.log(err);
      setError(locale?.invalidQrErrMsg);
    }
  };

  const handleError = err => {
    if (err?.name === 'NoVideoInputDevicesError') {
      setError(locale?.browserNotSupportedErrMsg);
      return;
    }

    setError(typeof err === 'object' ? err.message : err);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      className={classes.dialog}
    >
      <Collapse in={!!error} timeout={600} unmountOnExit mountOnEnter>
        <Alert severity="error" className={classes.errorMsg}>
          {error}
        </Alert>
      </Collapse>
      <QrReader delay={500} onError={handleError} onScan={handleScan} style={{ width: '100%' }} />
      <DialogActions className={classes.actions}>
        <Button onClick={onClose} fullWidth={!error} color="primary" size="large" autoFocus>
          {locale?.close}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ScanQr;
