import React, { useEffect, useState } from 'react';
import useStyles from './styles';

function AnimatedBG({ isExpanded, children }) {
  const [expanded, setExpanded] = useState(null);
  const classes = useStyles({ expanded });

  useEffect(() => {
    isExpanded ? setExpanded(true) : setExpanded(false);
  }, [isExpanded]);

  return (
    <>
      <div className={classes.root} />
      <div className={classes.bg} />
      {children}
    </>
  );
}

export default AnimatedBG;
