import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Welcome from 'pages/Welcome/Welcome';
import Manage from 'pages/Manage/Manage';
import Scan from 'pages/Scan/Scan';
import Queues from 'pages/Queues/Queues';
import AnimatedBG from 'components/AnimatedBG/AnimatedBG';
import Windows from 'pages/Windows/Windows';

function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/manage/">
          <Manage />
        </Route>
        <Route exact path="/printer/">
          <Manage />
        </Route>
        <Route exact path="/queues">
          <Queues />
        </Route>
        <Route exact path="/scan">
          <AnimatedBG isExpanded>
            <Scan />
          </AnimatedBG>
        </Route>
        <Route exact path="/windows">
          <Windows />
        </Route>
        <Route exact path="/">
          <AnimatedBG>
            <Welcome />
          </AnimatedBG>
        </Route>
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default Routes;
