import React from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import useStyles from './styles';

function Card({ children, ...props }) {
  const classes = useStyles();
  return (
    <Box overflow="hidden" className={classes.root}>
      <Box position="relative" clone boxShadow="0px 2px 1px -1px rgba(0,0,0,0.2)" {...props}>
        <Paper elevation={0}>
          <div className={`${classes.halfCircle} ${classes.rightCircle}`} />
          <div className={`${classes.halfCircle} ${classes.leftCircle}`} />
          {children}
        </Paper>
      </Box>
    </Box>
  );
}

export default Card;
