import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 463.981 463.981" {...props}>
      <path d="M215.999 431.981h-16a8 8 0 000 16h16a8 8 0 000-16zM226.647 2.781c-33.82-10.275-69.566 8.812-79.84 42.632a63.998 63.998 0 00-.167 36.648 8 8 0 009.92 5.44 8 8 0 005.44-9.92c-7.461-25.438 7.112-52.108 32.55-59.569 25.438-7.461 52.108 7.112 59.569 32.55A48 48 0 01254 77.981a8 8 0 0015.28 4.64c10.274-33.82-8.813-69.566-42.633-79.84z" />
      <path d="M383.999 392.621v-22.8a72.39 72.39 0 012.16-17.84l6.88-27.44a167.998 167.998 0 004.16-57.92l-8-50.48c-3.841-23.2-23.924-40.201-47.44-40.16h-5.76a7.997 7.997 0 00-2.8.56 39.998 39.998 0 00-32-16.56h-5.2a24 24 0 00-10.32 2.32 40 40 0 00-33.44-18.32h-4.24a24.018 24.018 0 00-8 1.44v-81.44c0-17.673-14.327-32-32-32h-1.52c-16.892-.036-30.902 13.064-32 29.92l-11.52 181.44a70.164 70.164 0 01-26.08-16.56l-7.2-7.2c-15.611-15.562-40.869-15.562-56.48 0l-4.56 4.4a16 16 0 00-1.52 20.8l15.12 20.24a125.9 125.9 0 017.6 11.68l1.6 3.28a214.075 214.075 0 0076.56 76.48 16.001 16.001 0 018 13.84v21.68a7.599 7.599 0 000 .88c-10.64 8-16 20.8-16 39.12v24a8 8 0 0016 0v-24c0-23.04 8.96-32 32-32h144c24 0 32 8 32 32h-136a8 8 0 000 16h136v8a8 8 0 0016 0v-24c0-18.72-5.44-32-16-39.36zm-5.44-71.6l-6.88 27.44a88.02 88.02 0 00-3.68 21.36v16a72.465 72.465 0 00-16-1.84h-144a69.784 69.784 0 00-16 1.84v-15.44a32.002 32.002 0 00-16-27.92 197.592 197.592 0 01-70.72-70.48l-1.28-2.88a139.883 139.883 0 00-8.8-13.12l-15.2-20.56 4.48-4.48c9.37-9.359 24.55-9.359 33.92 0l7.2 7.2a85.683 85.683 0 0036.32 21.76l-1.12 16.88a8 8 0 007.2 8.56h.8a8 8 0 008-7.52l13.76-214.88c.549-8.428 7.554-14.978 16-14.96h1.44c8.837 0 16 7.163 16 16v136a8 8 0 0016 0v-32a8 8 0 018-8h4.24c12.046-.119 22.314 8.712 24 20.64l3.76 28.48a8 8 0 008 6.88h1.12a8.001 8.001 0 006.902-8.965l-.022-.155-4.08-28.56v-.88a7.997 7.997 0 014.08-1.44h4.88c11.864-.159 22.063 8.374 24 20.08l3.12 21.28a8 8 0 008 6.64h1.36a8 8 0 006.64-9.36l-3.52-21.28v-1.44h1.84c15.904-.192 29.536 11.326 32 27.04l8 50.08c1.762 17.42.49 35.014-3.76 52z" />
    </svg>
  );
}

export default SvgComponent;
