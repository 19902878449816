export default {
  language: 'عربي',
  thankYou: 'Thank you for waiting',
  yourPosition: "Here's your position in queue",
  ticket: 'Ticket',
  people: 'People before you',
  current: 'Currently serving',
  leave: 'Leave Queue',
  postpone: 'Postpone',
  yourTurn: "It's your turn now!",
  almostYourTurn: 'Few people left ahead of you, please be ready!',
  welcome: 'Welcome!',
  pleaseScan: 'Scan a QR code to get a virtual ticket',
  peopleInQueue: 'Total people in queue',
  next: 'Next',
  queue: 'Queue',
  getTicket: 'Get a virtual ticket',
  cancel: 'Cancel',
  ppWarnTitle: 'Postpone your Turn?',
  ppWarnDescription: 'Warning! Your turn will be transferred to the end of the queue',
  ppWarnProceedLabel: 'Postpone my turn',
  leaveWarnTitle: 'Leave queue?',
  leaveWarnDescription: 'This action is irreversible, do you still want to leave?',
  leaveWarnProceedLabel: 'Leave Queue',
  printTicket: 'Print a Ticket',
  viewMenu: 'View Services',
  close: 'Close',
  submit: 'Submit',
  submitRating: 'Rate',
  rate: 'Rate your experience at queue',
  swipeTip: 'You are now in multiple queues, swipe right and left to toggle between them.',
  gotit: 'Got it',
  scanQueue: 'Scan Queue',
  retry: 'Retry',
  invalidQrErrMsg: 'This QR code seems to be invalid',
  browserNotSupportedErrMsg:
    'The browser you are using is not supported, please use device camera to scan the QR code',
  selectWindow: 'Please Select Your Service',
  phoneNumberDialogTitle: 'Please Enter Your Phone Number',
  phoneNumber: 'Phone Number',
  skip: 'Skip',
  phoneValidationError: 'Please Enter a Valid Phone Number',
};
