import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, palette, shadows, mixins, breakpoints }) => ({
  root: {
    padding: spacing(2, 1),
    animation: '$rotate 6s infinite 1s linear',
    [breakpoints.up('sm')]: {
      marginBottom: spacing(2),
    },
  },
  bullet: {
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    margin: spacing(0, 1.25),
    width: spacing(2.5),
    height: spacing(2.5),
    borderRadius: '50%',
    transition: 'all 300ms ease-in-out',
    backgroundColor: palette.grey[400],
    boxShadow: shadows[2],
  },
  selected: {
    transform: 'scale(1.2)',
    backgroundColor: palette.primary.dark,
    boxShadow: shadows[1],
  },
  divider: {
    width: 15,
    backgroundColor: palette.divider,
  },
  ...mixins.keyframes.rotate,
}));
