import React from 'react';
import Box from '@material-ui/core/Box';
import ticketBG from 'assets/images/ticket.png';
import Number from 'components/Number/Number';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';

function TicketCard({ label, number, loading, ...props }) {
  const classes = useStyles();

  return (
    <Box className={classes.root} position="relative" {...props}>
      <img src={ticketBG} alt="" />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        px={4}
        pt={3.5}
        pb={1.8}
        position="relative"
      >
        <Typography variant="h5">{label}</Typography>
        <Number
          loading={loading}
          typographyProps={{
            variant: 'h1',
            align: 'center',
          }}
          skeletonProps={{
            width: 138,
            height: 104,
          }}
        >
          #{number}
        </Number>
      </Box>
    </Box>
  );
}

export default TicketCard;
