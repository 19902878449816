import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  dialogContent: {
    '&&&': {
      padding: 0,
    },
  },
  closeButton: {
    borderRadius: 0,
  },
}));
